import createCache from "@emotion/cache";
import griddie from "styled-griddie";

export const createMyCache = () =>
  createCache({
    key: "my-prefix-key",
    // plugin to fix grid issues with IE
    stylisPlugins: [griddie],
    // prefix based on the css property
    prefix: (key) => {
      switch (key) {
        case "flex":
          return false;
        case "transform":
        default:
          return true;
      }
    },
  });

export const myCache = createMyCache();
