import React from "react";
import PropTypes from "prop-types";
//import {StyleSheetManager} from 'styled-components'
import { CacheProvider } from "@emotion/core";
import { myCache } from "./create-emotion-cache";
import "typeface-roboto";
import "tailwindcss/dist/base.css";
import "./src/ie-svg-hack.css";
import "./src/ie-vars-hack";
import "./src/ie-array-flat-polyfill";
import { name, version } from "./package.json";

import "./src/ie-css-grid-hack";

console.info(`${name} ${version}`);

window
  .fetch(`${process.env.GATSBY_STATIC_BASE_URL}/version.json`, {
    cache: "no-cache"
  })
  .then(res => res.json())
  .then(json => console.log(`cp-ctnet-public--static: ${json.version}`));

export const onRouteUpdate = ({ location, prevLocation }) => {
  const _paq = window._paq;
  if (_paq) {
    location?.pathname && _paq.push(["setCustomUrl", location.pathname]);
    _paq.push(["setDocumentTitle", document.title]);
    prevLocation?.pathname &&
      _paq.push(["setReferrerUrl", prevLocation.pathname]);
    _paq.push(["trackPageView"]);
  }
};

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={myCache}>{element}</CacheProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node
};
